.rides_contain {
    margin-left: 250px;
}
.rides_title{
    font-size: 35px;
    margin-top: 10px;
}
.menu_filter{
    width:95%;
    margin-bottom: 10px;
    margin-top: 10px;

}
.menu_filter button{
    height: 40px;
    border-radius: 10px;
    font-size: 100%;
    color: whitesmoke;
    border: solid 1px;
    border-color: gray;
}
.menu_filter select{
    height: 40px;
    border-radius: 10px;
    font-size: 1vw;
    color: whitesmoke;
    border: solid 1px;
    border-color: gray;
}

.btn_filter{
    background-color: gray;
    margin-left: 20px;
    float: right;
    border: solid 1px;
    border-color: gray;
}
.leftside_menu{
    width:20%;
}
.rides_search{
    height: 40px;
    width: 40%;
    border-radius: 10px;
    font-size: 1vw;
}
.rightside_menu{
    width: 80%;
    float: right;
}
.rides_button_div{
    float: right;
    width: 100%;
}
.rides_fleets_select{
    background-color: gray;
    margin-left: 10px;
    width: 25%;
}
.curencies_select{
    height: 40px;
    border-radius: 10px;
    font-size: 1vw;
    background-color: gray;
    margin-left: 10px;
}
.rides_anaylysis{
    width: 95%;
}
.rides_anaylysis p{
    font-size: 120%;
    color:gray;
    margin-top: 0px;
}
.rides_anaylysis b{
    font-size: 120%;
}
.rides_anaylysis td{
    width: 25%;
}
.rides_in_use{
    height: 100px;
    border: solid 1px;
    border-color: gray;
    border-radius: 5px;
}
.rides_icon_analysis{
    font-size: 300%;
    width: 10%;
    text-align: center;
}
.rides_in_use_table{
    width: 100%;
}
.rides_table_panel{
    margin-top: 25px;
}.rides_table_div{
    width: 95%;
    overflow-y: auto;
}
.rides_table{
    width: 150%;
    font-size: 1vw;
    text-align: center;
    border-collapse: collapse;
    margin-top: 10px;
}
.rides_table_menu_filter{
    width: 95%;
}
.rides_filter_menu{
    float: right;   
    padding-left: 25px;
}
.rides_filter_menu td{   
    padding-left: 25px;
}
.rides_btn_csv{
    height: 40px;
    width: 100%;
    border-radius: 10px;
    font-size: 100%;
    background-color: green;
    color: gray;
    float: right;
    margin-left: 25px;
}
.rides_btn_filter{
    font-size: 100%;
    height: 40px;
    width: 20%;
    border-radius: 5px;
    background-color: gray;
}
.rides_table td{
    border-bottom: solid 1px;
    border-color: silver;
    border-spacing: 0px 0px;   
}
.rides_table_tr:hover{
    background-color: whitesmoke;
}
.rides_table_th{
    background-color: black;
    color: whitesmoke;
    font-weight: 50%;
}
.rides_table_tr{
    color: #758d96;
}
.rides_average_length_table{
    padding-bottom: 0px;
    padding-top: 0px;
    font-size: 0.75vw;
}
.btn_get_rides_csv{
    margin-left: 10px;
    font-size: 100%;
    height: 40px;
    width: 20%;
    border-radius: 5px;
    background-color: gray;
}
.complaint_dismiss_button{
    height: 35px;
    line-height: 25px;
    border-radius: 5px;
    background-color: #bf00ff;
    border-color: #bf00ff;
    margin-top: 5px;
    margin-bottom: 5px;
    color: whitesmoke;
    margin-right: 5px;
}
.complaint_confirm_button{
    height: 35px;
    line-height: 25px;
    border-radius: 5px;
    background-color: #0080ff;
    border-color: #0080ff;
    margin-top: 5px;
    margin-bottom: 5px;
    color: whitesmoke;
    margin-right: 5px;
}
.complaint_evidence_button{
    height: 35px;
    line-height: 25px;
    border-radius: 5px;
    background-color: #00ff80;
    border-color: #00ff80;
    margin-top: 5px;
    margin-bottom: 5px;
    color: whitesmoke;
    margin-right: 5px;
}
.no_complaint_button{
    height: 35px;
    line-height: 25px;
    border-radius: 5px;
    background-color: grey;
    border-color: grey;
    margin-top: 5px;
    margin-bottom: 5px;
    color: whitesmoke;
    margin-right: 5px;
}
.complaint_form_td{
    width: 50%;
}
.show_complaint_form{
    display: block;
}
.hide_complaint_form{
    display: none;
}
.complaint_form_table{
    width: 100%;
}
.complaint_form_div{
    background-color:#bebebe;
    margin-top:5%;
    width: 100%;
    padding: 1%;
    border-radius: 5px;
    border-color: grey;
}
.complaint_button{
    padding-left: 15%;
}
.user_img_1{
    text-align: center;
}
.user_img_2{
    text-align: center;
}