.notification {
  display: inline-block;
  position: relative;
  background-color: transparent;
  border-radius: 15px;
  box-shadow: none;
  cursor: pointer;
}

.notification::after {
  font-family: Arial;
  position: absolute;
  top: 0px;
  right: -15px;
  padding: 5px 8px;
  line-height: 100%;
  border: 1px #fff solid;
  border-radius: 20px;
  background: red;
  opacity: 0;
  content: attr(data-count);
  opacity: 0;
  transform: scale(0.1);
  transition: transform, opacity;
  transition-timing-function: ease-out;
}
.notification-info-panel .notification-message.unread {
  background-color: #696969;
  color: #FFFFFF;
}
#popover-contained{
  width: 272px;
}
