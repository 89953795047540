.vehicles_type_contain {
    margin-left: 250px;
}
.vehicles_type_title{
    font-size: 35px;
}
.vehicles_type_table_contain{
    display: block;
}
.hide_vehicle_table_contain{
    display: none;
    visibility: hidden;
}
.vehicles_menu_filter{
    width:95%;
    margin-bottom: 5px;
    margin-top: 10px;
}
.vehicles_menu_filter button{
    height: 40px;
    border-radius: 10px;
    font-size: 1vws;
    color: whitesmoke;
}
.vehicles_menu_filter select{
    height: 40px;
    border-radius: 10px;
    font-size: 1vw;
    background-color: gray;
    color: whitesmoke;
}
.vehicles_btn_get_csv{
    background-color: gray;
    margin-left: 10px;
    border: none;
}
.btn_add_vehicle{
    background-color: gray;
    margin-left: 10px;
    display: inline-block;
    border: none;
}
.btn_add_multiple_vehicle{
    background-color: gray;
    margin-left: 10px;
    display: inline-block;
    border: none;
}
.btn_qr_code{
    background-color: gray;
    margin-left: 10px;
    border: none;
}
.vehicles_fleets_select{
    background-color: gray;
    margin-left: 10px;
    border:none;
}
.vehicles_leftside_menu{
    width:50%;
}
.vehicles_flag_select{
    height: 40px;
    width: 30%;
    border-radius: 10px;
    font-size: 1vw;
    border: solid 1px;
    border-color: gray;
}
.status_select{
    height: 40px;
    width: 30%;
    border-radius: 15px;
    font-size: 1vw;
    margin-left: 50px;
}
.vehicles_rightside_menu{
    width: 50%;
    text-align: right;
}
.vehicles_rightside_button{
    width: ;
    float: right;
    border: none;
}
.location_search{
    height: 40px;
    width: 60%;
    border-radius: 10px;
    font-size: 1vw;
}
.open_toggle{
    display: inline-block;
    margin-left: 10px;
    z-index: -1;
}
.open_without_ride_toggle{
    z-index: -1;
    margin-left: 10px;
}
.vehicles_btn_csv{
    height: 40px;
    border-radius: 10px;
    font-size: 1vw;
    background-color: green;
    color: white;
    margin-left: 15px;
    border: solid 1px;
    border-color: green;
}
.vehicles_table_div{
    width: 100%;
}
.vehicles_table_div::-moz-scrollbar{
    background-color: red;
}
.vehicles_table{
    width: 150%;
    font-size: 1vw;
    text-align: center;
    border-collapse: collapse;
}
.vehicles_table td{
    border-bottom: solid 1px;
    border-color: silver;
    border-spacing: 0px 0px;
}
.vehicles_table_th{
    background-color: black;
    color: whitesmoke;
    font-weight: 50%;
}
.battery{
    background-color: #000;
    border: 0.5px solid rgba(44, 64, 90, 0.2);
    height: 21px;
    position: relative;
    width: 50px;
    line-height: 20px;
    color: white;
    margin-top: 2px;
    border-radius: 3px;
    overflow: hidden;
}
.battery span{
    position: absolute;
    font-size: 11px;
    margin: 0;
    left: 0;
    right: 0;
    text-align: center;
}
.battery-level-80{
    background-color: #00c853;
    width:80%; 
    height: 100%;
    padding-left:4px;
    font-size:9px;
}
.battery-level-50{
    background-color: #3f51b5;
    width:50%; 
    height: 100%;
    padding-left:4px;
    font-size:9px;
}
.battery-level-75{
    background-color: #00c853;
    width:75%; 
    height: 100%;
    padding-left:4px;
    font-size:9px;
}
.battery-level-77{
    background-color: #00c853;
    width:77%; 
    height: 100%;
    padding-left:4px;
    font-size:9px;
}
.battery-level-91{
    background-color: #00c853;
    width:91%; 
    height: 100%;
    padding-left:4px;
    font-size:9px;
}
.flags{
    width:30px;
    height:30px;
}
.btn-map{
    height: 35px;
    width: 65px;
    line-height: 25px;
    border-radius: 5px;
    background-color: #00c853;
    border-color: #00c853;
    margin-top: 5px;
    margin-bottom: 5px;
}
.map-icons{
    margin-top: 0px;
    margin-bottom: 0px;
    height: 60%;
    width: 60%;
    color: whitesmoke;
}
.show_vehicle_map{
    display: block;
}
.hide_vehicle_map{
    display: none;
}
.add_vehicle_form_header{
    display: block;
    margin-left: 50px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 20px;
}
.form {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
      
}
.form-group label {
    font-size: 20px;
}
.form-group  input {
    margin-top: 6px;
    min-width: 18em;
    height: 37px;
    padding: 0px 10px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    background-color: #f3f3f3;
    border: 0;
    border-radius: 4px;
    margin-bottom: 15px;
    transition: all 250ms ease-in-out;
}
.form-group  select {
    margin-top: 6px;
    min-width: 18em;
    height: 37px;
    padding: 0px 10px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    background-color: #f3f3f3;
    border: 0;
    border-radius: 4px;
    margin-bottom: 15px;
    transition: all 250ms ease-in-out;
}
.form-group  output {
    margin-top: 6px;
    min-width: 18em;
    height: 37px;
    padding: 0px 10px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    background-color: #f3f3f3;
    border: 0;
    border-radius: 4px;
    margin-bottom: 15px;
    transition: all 250ms ease-in-out;
    float:left;
}
.required:after {
    content:" *";
    color: red;
}
.form-group-left-side{
    margin-left: 50px;
    width: 28%;
}
.form-group-center-side{
    margin-left: 50px;
    width: 28%;
}
.form-group-right-side{
    margin-left: 50px;
    width: 28%;
}
.hide_add_vehicle_form{
    display: none;
}
.show_add_vehicle_form{
    display: block;
    position: absolute;
    top: 15%;
    margin-left: 250px;
    height: 550px;
    width: 80%;
    background-color: #f8f8f8;
    border-radius: 10px;
    margin-top: 50px;
    z-index: 999;
}
.btn_cancel_adding{
    height: 40px;
    border-radius: 10px;
    font-size: 1vw;
    background-color: gray;
    color: white;
    margin-left: 25px;
    border: solid 1px;
    border-color: gray;
    padding-left: 10px;
    padding-right: 10px;
} 
.show_add_vehicle_form_footer{
    display: block;
    align-items: center;
    margin-left: 40%;
}
.hide_add_vehicle_form_footer{
    display: none;
}
.hide_confirm_add_vehicle{
    display: none;
}
.show_confirm_add_vehicle{
    display: block;
    position: absolute;
    top: 35%;
    left:50%;
    margin-left: -20%;
    height: 150px;
    width: 40%;
    background-color: #c2bfbf;
    border-radius: 5px;
    border: solid 1px;
    border-color: black;
    text-align: center;
    padding-top: 25px;
    z-index: 9999;
}
.add_vehicle_confirm_title{
    margin-top: 50px;
    font-size: 1vw;
}
.add_vehicle_confirm_button{
    width: 23%;
    height: 35px;
    border-radius: 5px;
    color: whitesmoke;
    background-color: #545096;
    border-color: #545096;
    box-shadow: none;
}
.vehicle_action_button{
    height: 35px;
    width: 65px;
    line-height: 25px;
    border-radius: 5px;
    background-color: gray;
    border-color: gray;
    margin-top: 5px;
    margin-bottom: 5px;
    color: whitesmoke;
    margin-right: 5px;
}
.show_edit_vehicle_form{
    display: block;
    width: 95%;
    border: solid 1px gray;
    border-radius: 10px;
    margin-top: 10px;
}
.hide_edit_vehicle_form{
    display: none;
}
.edit_vehicle_form_header{
    font-size: 1.5vw;
    margin-bottom: 15px;
    margin-left: 10px;
    margin-top: 10px;
}
.show_edit_vehicle_form_footer{
    display: block;
    align-items: center;
    margin-left: 40%;
    margin-bottom: 10px;
}
.hide_edit_vehicle_form_footer{
    display: none;
}
.hide_confirm_edit_vehicle{
    visibility: hidden;
}
.show_confirm_edit_vehicle{
    position: absolute;
    top: 100%;
    left:50%;
    margin-left: -20%;
    height: 150px;
    width: 40%;
    background-color: #c2bfbf;
    border-radius: 5px;
    border: solid 1px;
    border-color: black;
    text-align: center;
    padding-top: 25px;
    z-index: 9999;
}
.edit_vehicle_confirm_title{
    margin-top: 50px;
    font-size: 1vw;
}
.edit_vehicle_confirm_button{
    width: 23%;
    height: 35px;
    border-radius: 5px;
    color: whitesmoke;
    background-color: #545096;
    border-color: #545096;
    box-shadow: none;
}