.permissions_contain {
    margin-left: 250px;
}
.permissions_title{
    font-size: 35px;
    margin-top: 25px;
}
.permissions_menu_bar{
    width:95%;
    margin-bottom: 10px;
    margin-top: 10px;
}
.leftside_menu{
    width:50%;
}
.permissions_search{
    height: 40px;
    width: 30%;
    border-radius: 10px;
    font-size: 100%;
}

.rightside_menu{
    width: 50%;
    float: right;
}

.permissions_table{
    width: 95%;
    font-size: 1vw;
    text-align: center;
    border-collapse: collapse;
}
.permissions_table td{
    border-bottom: solid 1px;
    border-color: rgb(197, 188, 188);
    border-spacing: 0px 0px;
    color: gray;
}
.permissions_table th{
    background-color: black;
    color: whitesmoke;
    font-weight: 25%;
}
.permissions_toggle {
    margin: auto;
    position: absolute;
}