.org_contain {
    margin-left: 250px;
}
.org_title{
    font-size: 35px;
    margin-top: 25px;
}
.org_menu_filter{
    width:95%;
    margin-bottom: 25px;
    margin-top: 25px;
}
.org_leftside_menu{
    width:50%;
}
.org_country_select{
    height: 40px;
    width: 30%;
    border-radius: 10px;
    font-size: 1vw;
    border: solid 1px;
    border-color: gray;
}
.org_status_select{
    height: 40px;
    width: 30%;
    border-radius: 10px;
    font-size: 1vw;
    margin-left: 50px;
    border: solid 1px;
    border-color: gray;
}
.org_rightside_menu{
    width: 50%;
    text-align: right;

}
.org_location_search{
    height: 40px;
    width: 50%;
    border-radius: 10px;
    font-size: 1vw;
    border: solid 1px;
    border-color: gray ;
}
.org_btn_add_location{
    height: 40px;
    width: 35%;
    border-radius: 10px;
    font-size: 1vw;
    background-color: gray;
    color: white;
    margin-left: 25px;
    border: solid 1px;
    border-color: gray;
}
.org_table{
    width: 95%;
    font-size: 1vw;
    text-align: center;
    border-collapse:collapse;
    overflow-y: auto;
}
.org_table td{
    border-bottom: solid 1px;
    border-color: gray;
    border-spacing: 0px 0px;
    /*display: inline;*/
}
.org_table button{
    width: 23%;
    height: 35px;
    margin-right:5px;
    margin-top: 5px;
    font-size: 1vw;
}
.org_table_th{
    background-color: black;
    color: whitesmoke;
    font-weight: 50%;
}
.org_action_button{
    border-radius: 5px;
    color: whitesmoke;
    background-color: grey;
}