.elevate-logo {
	transform: scale(0.3);
	position: absolute;
	left: 50%;
	margin-left: -500px;
	margin-bottom:190px;
}

.nav-info-bar{
  width: 30%;
  position: absolute;
  right: 0px;
  padding-right: 20px;
}
.nav-search-bar{
  float:left;
  margin-right: 10px;
	height: 30px;
	width: 60%;
	border-radius: 10px;
  margin-top: 10px;
}
.nav-notification-button{
  float:left;
  margin-right: 10px;
  font-size:  200%;
  background-color: transparent;
  border: none;
}
.nav-user-button{
  background-color: transparent;
  border: none;
  font-size: 200%;
}
.nav-help{
  float:left;
  margin-right: 10px;
  font-size: 2rem;
}
.nav-user{
  float:left;
  margin-right: 10px;
  font-size: 2rem;
}
.avatar_card_hide{
  position: absolute;
  top: 60px;
  height: auto;
  width: 200px;
  border: solid 1px;
  border-color: gray;
  border-radius: 5px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 0px;
  font-size: 15px;
  background-color: whitesmoke;
  box-shadow: whitesmoke;
  visibility: hidden;
  z-index: 999;

}
.avatar_card_show{
  position: absolute;
  top: 54px;
  right: 0;
  height: auto;
  width: 200px;
  border: solid 1px;
  border-color: gray;
  border-radius: 5px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 15px;
  background-color: whitesmoke;
  box-shadow: whitesmoke;
  margin-right: 10px;
  z-index: 999;
}
.side-navigation-panel{
  overflow-y: auto;
  height: 90%;
  scrollbar-color:#212529 #15171c;
  color: white
}
.side-navigation-panel::-webkit-scrollbar {
  width: 10px;               /* width of the entire scrollbar */
}
.notification-header{
    width: 100%;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    font-size: 1rem;
    margin-top: 30%;
    padding-left: 5%;
    padding-top: 1%;
}
.hide_notification{
    display: none; 
}
.show_notification{
    display: block;
    height: 50%;
    width: 15%;
    margin-left:-17%;
    margin-top:0.25%;
    background-color: whitesmoke;
    position:fixed;
    left:100%;
    border: solid;
    border-color: gray;
    border-radius: 5px;
    border-width: thin;
    z-index: 999;
    overflow: scroll;
}
.mark_as_read_button{
    border: solid;
    border-color: whitesmoke;
    border-width: thin;
    background-color: whitesmoke;
    color: #427ef5;
    box-shadow: none;
    cursor: pointer;
}
.notification_content_list{
    
}
.notification_content_ul{
    list-style-type: none;
    padding-left:2%;
    padding-right:2%;
}
.notification_content{
    height: 80%;
    padding: 5%;
    border: solid;
    border-color: gray;
    border-radius: 5%;
    border-width: thin;
    background-color: #6c6e71;
    margin-bottom: 1%;
    margin-left: 0px;
}
.show_unread_count{
    display:block;
    color: whitesmoke;
    font-size: 1em;
    //padding:0.2em;
    border: solid;
    border-color: whitesmoke;
    border-radius: 0.6em;
    border-width: thin;
    background-color: red;
    text-align: center;
    width:30%;
    margin-left:-5%;
}
.hide_unread_count{
    display:none;
}

/*
.side-navigation-panel-select-option-text{
    color:whitesmoke;
}
.side-navigation-panel-select : hover{
    color:rgb(31, 41, 55);
}*/