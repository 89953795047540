.taxes_contain{
    margin-left: 250px;
}
.taxes_table{
    width: 95%;
    border-collapse: collapse;
    margin-top: 10px;
}
.taxes_table_tr{
    border-bottom: solid 1px;
    border-color: gray;
}
.taxes_table_th{
    color: black;
    background-color: gray;
}
.taxes_table_tr:hover{
    background-color: whitesmoke;
}
.taxes_title{
    text-align: left;
    font-size: 35px;
}
.taxes_leftside_menu{
    text-align: left;
    width: 50%;
}
.taxes_search{
    width: 30%;
    height: 40px;
    border-radius: 5px;
    font-size: 1vw;
}
.btn_add_taxes{
    width: 25%;
    height: 40px;
    border-radius: 5px;
    font-size: 1vw;
    background-color: gray;
    color: whitesmoke;
}
.taxes_rightside_menu{
    text-align: right;
    width: 50%;
}
.payments_btn_previous{
    width: 15%;
    height: 40px;
    border-radius: 5px;
    background-color: gray;
    color: whitesmoke;
}
.payments_btn_next{
    width: 15%;
    height: 40px;
    border-radius: 5px;
    background-color: gray;
    color: whitesmoke;
}
.taxes_pagination{
    width: 95%;
    margin-top: 25px;
}
.total_showing{
    text-align: left;
    color: gray;
}
.page_btn_div{
    text-align: right;
}