.parkings_contain {
    margin-left: 250px;
}
.parkings_title{
    font-size: 35px;
    margin-top: 5px;
}
.parkings_menu_filter{
    width:95%;
    margin-bottom: 5px;
    margin-top: 10px;
}
.parkings_menu_filter button{
    height: 40px;
    border-radius: 10px;
    font-size: 100%;
    color: whitesmoke;
}
.parkings_menu_filter select{
    height: 40px;
    border-radius: 10px;
    font-size: 100%;
    background-color: gray;
    color: whitesmoke;
}
.btn_get_csv{
    background-color: gray;
    margin-left: 10px;
}
.btn_add_parkings{
    background-color: gray;
    margin-left: 10px;
    display: inline-block;
    border: solid 1px gray;
}
.owner_select{
    background-color: gray;
    margin-left: 10px;
    width: 20%;
    border: gray;
}

.fleets_select{
    background-color: gray;
    margin-left: 10px;
    width: 20%;
    border: gray;
}
.parkings_leftside_menu{
    width:50%;
}
.parkings_rightside_menu{
    width: 50%;
    text-align: right;
}
.parkings_rightside_button{
    float: right;
}
.location_search{
    height: 40px;
    width: 60%;
    border-radius: 10px;
    font-size: 100%;
}
.open_toggle{
    display: inline-block;
    z-index: -1;
}
.btn_csv{
    height: 40px;
    width: 100%;
    border-radius: 10px;
    font-size: 100%;
    background-color: green;
    color: white;
    margin-left: 25px;
}
.parkings_table{
    width: 95%;
    font-size: 1vw;
    text-align: center;
    border-collapse: collapse;
}
.parkings_table td{
    border-bottom: solid 1px;
    border-color: silver;
    border-spacing: 0px 0px;
}
.parkings_table_th{
    background-color: black;
    color: whitesmoke;
}
.parkings_table_tr:hover{
    background-color: whitesmoke;
}
.parkings_btn_previous{
    width: 15%;
    height: 40px;
    border-radius: 5px;
    background-color: gray;
    color: whitesmoke;
}
.parkings_btn_next{
    width: 15%;
    height: 40px;
    border-radius: 5px;
    background-color: gray;
    color: whitesmoke;
}
.parkings_pagination{
    width: 95%;
    margin-top: 25px;
}
.total_showing{
    text-align: left;
    color: gray;
}
.page_btn_div{
    text-align: right;
}
.parkings_table{
    width: 95%;
    font-size: 0.9vw;
    text-align: left;
    border-collapse: collapse;
}
.parkings-form {
    margin-top: 2em;
    margin-left: 20px;
}
.form-group-left-side{
    width: 45%;
}
.form-group-right-side{
    width: 45%;
}
.form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
}
.form-group label {
    font-size: 1vw;
}
.form-group  input {
    margin-top: 3px;
    min-width: 15em;
    height: 34px;
    width: 40%;
    padding: 0px 10px;
    font-size: 1vw;
    font-family: "Open Sans", sans-serif;
    background-color: #f3f3f3;
    border: 0;
    border-radius: 4px;
    margin-bottom: 15px;
    transition: all 250ms ease-in-out;
}
.form-group  select {
    margin-top: 3px;
    min-width: 15em;
    height: 34px;
    width: 100px;
    padding: 0px 10px;
    font-size: 1vw;
    font-family: "Open Sans", sans-serif;
    background-color: #f3f3f3;
    border: 0;
    border-radius: 4px;
    margin-bottom: 15px;
    transition: all 250ms ease-in-out;
}
.form-group  input[type=checkbox] {
    margin-top: 3px;
    min-width: 15em;
    height: 34px;
    padding: 0px 10px;
    font-size: 1vw;
    font-family: "Open Sans", sans-serif;
    background-color: #f3f3f3;
    border: 0;
    border-radius: 4px;
    margin-bottom: 15px;
    transition: all 250ms ease-in-out;
}
.form-group input[type=checkbox] {
    height: 1vw;
    width: 1vw;
}
.addParkingsMap{
    border-radius: 10px;
    margin-left: 10px;
    width: 50%;
    height: 350px;
}
.add_parkings_confirm_title{
    margin-top: 50px;
    font-size: 1vw;
}
.add_parkings_confirm_button{
    width: 23%;
    height: 35px;
    border-radius: 5px;
    color: whitesmoke;
    background-color: #545096;
    border-color: #545096;
    box-shadow: none;
}
.add_parkings_hide{
    display: none;
}
.add_parkings_show{
    display:flex;
    position: absolute;
    top: 15%;
    width: 80%;
    margin-top: 50px;
}
.confirm_add_parkings_hide{
    display: none;
}
.confirm_add_parkings_show{
    position: absolute;
    top: 35%;
    left:50%;
    margin-left: -20%;
    height: 150px;
    width: 40%;
    background-color: #c2bfbf;
    border-radius: 5px;
    border: solid 1px;
    border-color: black;
    text-align: center;
    padding-top: 25px;
    z-index: 999;
}
.add_parkings_footer{
    display: block;
    align-items: center;
    margin-left: 20%;
    justify-content: flex-end;
}
.hide_add_parkings_footer{
display: none;
}
.show_parkings_table{
    display: block;
}
.hide_parkings_table{
    display: none;
}
.addParkingsForm{
    border-radius: 10px;
    width: 50%;
    background-color: #f8f8f8;
    height: auto;
}
.addParkingsMap{
    border-radius: 10px;
    margin-left: 10px;
    width: 50%;
    height: 350px;
    z-index: 0;
}
.add_parkings_form_header{
    font-size: 1.2vw;
}
.confirm_add_area_hide{
    display: none;
}
.confirm_add_area_show{
    position: absolute;
    top: 35%;
    left:50%;
    margin-left: -20%;
    height: 150px;
    width: 40%;
    background-color: #c2bfbf;
    border-radius: 5px;
    border: solid 1px;
    border-color: black;
    text-align: center;
    padding-top: 25px;
    margin-top: 10px;
}
.show_confirm_use_area{
    display: block;
}
.hide_confirm_use_area{
    display: none;
}
.add_area_btn{
    margin-left: 10px;
    height: 20px;
    border-radius: 5px;
    border: solid 1px gray;
}
.edit_button_geofence{
    height: 28px;
    border-radius: 5px;
    border-color: gray;
    background-color: gray;
    color: white;
}