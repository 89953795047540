.div_all {
    margin-left: 250px;
}
.events_analysis_info{
    border-radius: 5px;
    width: 500px;
    height: 10px;
    border: solid 1px;
    border-color: gray;
    color: gray;
    font-size: 13px;
}
.dashboard_table {
    border-spacing: 20px 10px;
    margin-left: -20px;
}
.events_title {
    position: relative;
    font-size: 35px;
}
.events_filter_bar{
    width:95%;
    position: relative;
    text-align: center;
}
.events_filter_div{
    text-align: right;
}
.events_fleets_select {
    border-radius: 9px;
    width: 15%;
    height: 40px;
    margin-right: 25px;
    font-size: 1vw
}
.events_filter_select {
    border-radius: 9px;
    width: 15%;
    height: 40px;
    margin-right: 25px;
    font-size: 1vw;
}
.events_filter_vehicle {
    border-radius: 9px;
    width: 15%;
    height: 40px;
    margin-right: 25px;
    font-size: 1vw;
}
.events_details{
    position: relative;
    width: 95%;
    height: 400px;
    border: solid 1px;
    border-color: gray;
    border-radius: 5px;
    margin-top: 10px;
    overflow-y: auto;
}
.events_details_table{
    padding-left: 10px;
    padding-top: 10px;
}
.events_details_table_tr{
    border-bottom: solid 1px;
    border-color: #f2f2f2;
    height: 50px;
    padding-left: 15px;
    font-size: 1vw;
    margin-bottom: 5px;
}
.events_legend{
    width: 95%;
    text-align: center;
}
.events_legend_title{
    font-size: 120%;
    width: 95%;
    text-align: center;
    margin-top: 20px;
}
.events_flag{
    width: 95%;
    margin-top: 25px;
}
.event_legend_flag{
    width: 30px;
    height: 15px;
    border-radius: 2.5px;
}
.event_legend_flag_name{
    text-align: left;
}
.flag_label{
    font-size: 1vw;
}
.organisation_events_color{
    color: #FF5050;
}
.zone_events_color{
    color: #99E472;
}
.artist_event_color{
    color: #118DFF;
}
.client_events_color{
    color: #660033;
}
.offer_events_color{
    color: #ff9999;
}
.billing_plan_events_color{
    color: #990099;
}
.payment_events_color{
    color: #ff9966;
}
.tax_events_color{
    color: #cc0099;
}
.campaign_events_color{
    color: #ff6666;
}
.ticket_events_color{
    color: #ffcccc;
}
.offer_events_color{
    color: #cc99ff;
}
.billing_events_color{
    color: #42F9F9;
}
.banner_events_color{
    color: #CC6699;
}
.marker_events_color{
    color: #ff3399;
}
.feedback_events_color{
    color: #ff99cc;
}
.promo_events_color{
    color: #CCAA14;
}