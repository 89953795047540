.slow_speed_areas_contain {
    margin-left: 250px;
}
.slow_speed_areas_title{
    font-size: 35px;
    margin-top: 5px;
}
.slow_speed_areas_menu_filter{
    width:95%;
    margin-bottom: 5px;
    margin-top: 10px;
}
.slow_speed_areas_menu_filter button{
    height: 40px;
    border-radius: 10px;
    font-size: 100%;
    color: whitesmoke;
}
.slow_speed_areas_menu_filter select{
    height: 40px;
    border-radius: 10px;
    font-size: 100%;
    background-color: gray;
    color: whitesmoke;
}
.btn_get_csv{
    background-color: gray;
    margin-left: 10px;
}
.btn_add_slow_speed_areas{
    background-color: gray;
    margin-left: 10px;
    display: inline-block;
    border: solid 1px gray;
}
.owner_select{
    background-color: gray;
    margin-left: 10px;
    width: 20%;
}

.fleets_select{
    background-color: gray;
    margin-left: 10px;
    width: 20%;
    border: solid 1px gray;
}
.slow_speed_areas_leftside_menu{
    width:50%;
}
.slow_speed_areas_rightside_menu{
    width: 50%;
    text-align: right;
}
.slow_speed_areas_rightside_button{
    float: right;
}
.open_toggle{
    display: inline-block;
    z-index: -1;
}
.slow_speed_areas_table{
    width: 95%;
    font-size: 1vw;
    text-align: center;
    border-collapse: collapse;
}
.slow_speed_areas_table td{
    border-bottom: solid 1px;
    border-color: silver;
    border-spacing: 0px 0px;
}
.slow_speed_areas_table_th{
    background-color: black;
    color: whitesmoke;
}
.slow_speed_areas_table_tr:hover{
    background-color: whitesmoke;
}
.slow_speed_areas_btn_previous{
    width: 15%;
    height: 40px;
    border-radius: 5px;
    background-color: gray;
    color: whitesmoke;
}
.slow_speed_areas_btn_next{
    width: 15%;
    height: 40px;
    border-radius: 5px;
    background-color: gray;
    color: whitesmoke;
}
.slow_speed_areas_pagination{
    width: 95%;
    margin-top: 25px;
}
.total_showing{
    text-align: left;
    color: gray;
}
.page_btn_div{
    text-align: right;
}