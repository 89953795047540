.map_contain{
    margin-top: 15px;
    margin-left: 250px;
}
.title_map {
    font-size: 35px;
}
.table_map {
    text-align:left;
    width: 100%;
    border-spacing: 1px;
    margin-top: 10px;
}
.menubar_table{
    width: 95%;
}
.map_location_select {
    border-radius: 8px;
    height: 30px;
}
.style_search {
    border-radius: 8px;
    height: 30px;
    width: 230px;
    margin-right: 0;
}
.select_vehicle {
    border-radius: 8px;
    height: 30px;
    margin-left: 10px;
}
.map_btn_filter {
    background-color: gray;
    color: white;
    border: none;
    border-radius: 8px;
    height: 30px;
    margin-left: 10px;
}
