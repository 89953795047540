.fleets_contain {
    margin-top: 15px;
    margin-left: 250px;
}
.fleets_contain_hide {
    visibility: hidden;
}
.fleets_title{
    font-size: 35px;
}
.fleets_menu_filter{
    width:95%;
    margin-bottom: 10px;
    margin-top: 10px;
}
.fleets_leftside_menu{
    width:50%;
}
.fleets_rightside_menu{
    width: 50%;
    float: right;
}
.fleets_search{
    height: 40px;
    width: 60%;
    border-radius: 10px;
    font-size: 1vw;
}
.btn_add_fleets{
    height: 40px;
    width: 35%;
    border-radius: 10px;
    font-size: 100%;
    background-color: gray;
    color: white;
    float: right;
}
.fleets_table{
    width: 95%;
    font-size: 1vw;
    text-align: left;
    border-collapse: collapse;
}
.fleets_table_td{
    border-bottom: solid 1px;
    border-color: gray;
    border-spacing: 0px 0px;
    color: gray;
}
.fleets_table tr:hover{
    background-color: whitesmoke;
}
.fleets_table_th{
    background-color: black;
    color: white;
    font-weight: 50%;
    text-align: center;
}
.add_fleet_hide{
    visibility: hidden;
}
.add_fleet_show{
    display:flex;
    position: absolute;
    top: 15%;
    margin-left: 250px;
    width: 80%;
    margin-top: 50px;
}
.addFleetForm{
    border-radius: 10px;
    width: 50%;
    background-color: #f8f8f8;
    height: auto;
}
.addFleetMap{
    border-radius: 10px;
    margin-left: 10px;
    width: 50%;
    height: 350px;
}
.fleet-form {
    margin-top: 2em;
    margin-left: 20px;
}
.form-group-left-side{
    width: 45%;
}
.form-group-right-side{
    width: 45%;
}
.form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
}
.form-group label {
    font-size: 1vw;
}
.form-group  input {
    margin-top: 3px;
    min-width: 15em;
    height: 34px;
    width: 40%;
    padding: 0px 10px;
    font-size: 1vw;
    font-family: "Open Sans", sans-serif;
    background-color: #f3f3f3;
    border: 0;
    border-radius: 4px;
    margin-bottom: 15px;
    transition: all 250ms ease-in-out;
}
.form-group  select {
    margin-top: 3px;
    min-width: 15em;
    height: 34px;
    width: 100px;
    padding: 0px 10px;
    font-size: 1vw;
    font-family: "Open Sans", sans-serif;
    background-color: #f3f3f3;
    border: 0;
    border-radius: 4px;
    margin-bottom: 15px;
    transition: all 250ms ease-in-out;
}
.form-group  input[type=checkbox] {
    margin-top: 3px;
    min-width: 15em;
    height: 34px;
    padding: 0px 10px;
    font-size: 1vw;
    font-family: "Open Sans", sans-serif;
    background-color: #f3f3f3;
    border: 0;
    border-radius: 4px;
    margin-bottom: 15px;
    transition: all 250ms ease-in-out;
}
.form-group input[type=checkbox] {
    height: 1vw;
    width: 1vw;
}
.companyDetail{
    margin-bottom: 9px;
}
.show_company_detail{
    display: block;
}
.hide_company_detail{
    display:none;
}
.footer{
    display: block;
    align-items: center;
    margin-left: 20%;
    justify-content: flex-end;
}
.hide_footer{
display: none;
}
.add_fleet_footer{
    display: block;
    align-items: center;
    margin-left: 20%;
    justify-content: flex-end;
}
.hide_add_fleet_footer{
display: none;
}
.btn_cancel_adding{
    height: 40px;
    border-radius: 10px;
    font-size: 1vw;
    background-color: gray;
    color: white;
    margin-left: 25px;
    border: solid 1px;
    border-color: gray;
    padding-left: 10px;
    padding-right: 10px;
} 
.confirm_add_location_hide{
    display: none;
}
.confirm_add_fleet_show{
    position: absolute;
    top: 35%;
    left:50%;
    margin-left: -20%;
    height: 150px;
    width: 40%;
    background-color: #c2bfbf;
    border-radius: 5px;
    border: solid 1px;
    border-color: black;
    text-align: center;
    padding-top: 25px;
    z-index: 999;
}
.add_fleet_confirm_title{
    margin-top: 50px;
    font-size: 1vw;
}
.add_fleet_confirm_button{
    width: 23%;
    height: 35px;
    border-radius: 5px;
    color: whitesmoke;
    background-color: #545096;
    border-color: #545096;
    box-shadow: none;
}
.btn_submit_adding{
    height: 40px;
    border-radius: 10px;
    font-size: 1vw;
    background-color: #545096;
    color: white;
    margin-left: 25px;
    border: solid 1px;
    border-color: #545096;
    padding-left: 10px;
    padding-right: 10px;
} 