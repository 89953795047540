.role_contain {
    margin-left: 250px;
}
.role_contain_hide {
    visibility: hidden;
}
.role_title{
    font-size: 35px;
    margin-top: 5px;
}
.role_menu_bar{
    width:95%;
    margin-bottom: 5px;
    margin-top: 5px;
}
.leftside_menu{
    width:50%;
}
.rightside_menu{
    width: 50%;
    text-align: right;
}
.btn_add_role{
    height: 40px;
    width: 30%;
    border-radius: 10px;
    font-size: 1vw;
    background-color: gray;
    color: white;
    margin-left: 25px;
    float: right;
}
.role_table{
    width: 95%;
    font-size: 1vw;
    text-align: center;
    border-collapse: collapse;
}
.role_table td{
    border-bottom: solid 1px;
    border-color: rgb(197, 188, 188);
    border-spacing: 0px 0px;
}
.role_table th{
    /*background-color: black;*/
    font-weight: 50%;
}
.dataTables_paginate{
    float:right
}
.pagination{
    cursor: pointer;
}
.add_role_hide{
    visibility: hidden;
}
.add_role_show{
    position: absolute;
    top: 15%;
    margin-left: 250px;
    height: 550px;
    width: 80%;
    background-color: #f8f8f8;
    border-radius: 10px;
    margin-top: 50px;
}
.add_role_confirm_title{
    margin-top: 50px;
    font-size: 1vw;
}
.add_role_confirm_button{
    width: 23%;
    height: 35px;
    border-radius: 5px;
    color: whitesmoke;
    background-color: #545096;
    border-color: #545096;
    box-shadow: none;
    margin-left: 5px;
}
.add_role_cancel_button{
    width: 15%;
    height: 35px;
    border-radius: 5px;
    color: whitesmoke;
    background-color: gray;
    border-color: gray;
    box-shadow: none;
}
.confirm_add_role_hide{
    visibility: hidden;
}
.confirm_add_role_show{
    position: absolute;
    top: 35%;
    left:50%;
    margin-left: -20%;
    height: 150px;
    width: 40%;
    background-color: #c2bfbf;
    border-radius: 5px;
    border: solid 1px;
    border-color: black;
    text-align: center;
    padding-top: 25px;
}
.form {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
      
}
.form-group label {
    font-size: 20px;
}
.form-group  input {
    margin-top: 6px;
    min-width: 18em;
    height: 37px;
    padding: 0px 10px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    background-color: #f3f3f3;
    border: 0;
    border-radius: 4px;
    margin-bottom: 15px;
    transition: all 250ms ease-in-out;
}
.form-group  select {
    margin-top: 6px;
    min-width: 18em;
    height: 37px;
    padding: 0px 10px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    background-color: #f3f3f3;
    border: 0;
    border-radius: 4px;
    margin-bottom: 15px;
    transition: all 250ms ease-in-out;
}
.form-group  output {
    margin-top: 6px;
    min-width: 18em;
    height: 37px;
    padding: 0px 10px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    background-color: #f3f3f3;
    border: 0;
    border-radius: 4px;
    margin-bottom: 15px;
    transition: all 250ms ease-in-out;
    float:left;
}
.add_role_footer{
    align-items: center;
    margin-left: 40%;
}
.add_role_footer_hide{
visibility: hidden;
}
.role_action_button{
    height: 35px;
    width: 65px;
    line-height: 25px;
    border-radius: 5px;
    background-color: gray;
    border-color: gray;
    margin-top: 5px;
    margin-bottom: 5px;
    color: whitesmoke;
    margin-right: 5px;
}
.showEditRoleForm{
    display: block;
}
.showEditRoleForm{
    display: block;
    margin-left: 250px;
    height: 450px;
    width: 80%;
    border: solid 1px gray;
    border-radius: 10px;
    background-color: #f8f8f8;
    z-index: 999;
}
.hideEditRoleForm{
    display: none;
}
.editFormFooter{
    display: block;
    align-items: center;
    margin-left: 40%;
}
.editFormFooter_hide{
    display: none;
}
.edit_role_hide{
    display: none;
}
.edit_role_show{
    position: absolute;
    top: 40%;
    margin-left: 250px;
    height: 150px;
    width: 40%;
    background-color: #f8f8f8;
    border-radius: 10px;
    margin-top: 50px;
    border: solid 1px gray;
    text-align: center;
    padding-top: 25px;
}
.delete_role_hide{
    display: none;
}
.delete_role_show{
    position: absolute;
    top: 30%;
    margin-left: 35%;
    height: 175px;
    width: 40%;
    background-color: #f8f8f8;
    border-radius: 10px;
    margin-top: 50px;
    border: solid 1px gray;
    text-align: center;
}
.showLoadingPermission{
    display: block;
    position: absolute;
    left: 45%;
    top: 40%;
    padding-top: 10px;
    height: 50px;
    width: 250px;
    background-color: gray;
    border: solid 1px bisque;
    border-radius: 10px;
    z-index: 999;
    text-align: center;
    color: whitesmoke;
    font-size: 1vw;
}
.hideLoadingPermission{
    display: none;
}