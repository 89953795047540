.riders_contain {
    margin-left: 250px;
}

.riders_title {
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.riders_menu_filter {
    width: 95%;
    margin-bottom: 25px;
    margin-top: 10px;

}

.riders_menu_filter button {
    height: 40px;
    border-radius: 5px;
    font-size: 100%;
    color: whitesmoke;
}

.riders_menu_filter select {
    height: 40px;
    border-radius: 5px;
    font-size: 100%;
    color: whitesmoke;
    background-color: gray;
}

.button_div {
    float: right;
}

.rider_btn_get_csv {
    background-color: gray;
    margin-left: 20px;
}

.rider_btn_filter {
    background-color: gray;
    margin-left: 20px;
}

.riders_leftside_menu {
    width: 100%;
}

.status_select {
    height: 40px;
    width: 30%;
    border-radius: 15px;
    font-size: 100%;
    margin-left: 50px;
}

.riders_rightside_menu {
    width: 100%;
    float: right;
}

.riders_search {
    height: 40px;
    width: 40%;
    border-radius: 10px;
    font-size: 100%;
}

.payment_select {
    border: solid 1px;
    border-color: gray;
    background-color: whitesmoke;
}

.active_toggle_filter {
    display: inline-block;
    z-index: -1;
}

.delete_toggle_filter {
    display: inline-block;
    z-index: -1;
}

.btn_csv {
    height: 60px;
    width: 100%;
    border-radius: 10px;
    font-size: 150%;
    background-color: green;
    border: solid 1px green;
    color: gray;
    float: right;
    margin-left: 25px;
}

.riders_table_div {
    width: 100%;
    overflow-y: auto;
}

.riders_table {
    width: 95%;
    font-size: 1vw;
    text-align: center;
    border-collapse: collapse;
    color: gray;
}

.riders_table td {
    border-bottom: solid 1px;
    border-color: silver;
    border-spacing: 0px 0px;
}
.dataTables_paginate li {
    display:inline-block;
    padding: 3px;
    border: solid 1px;
    border-color: silver;
    border-radius: 3px;
    margin-right:0px;
}
.sr-only {
    display:none;
}
.riders_table_tr:hover {
    background-color: whitesmoke;
}

.riders_table_th {
    background-color: black;
    color: whitesmoke;
    font-weight: 50%;
}

.riders_table_tr {
    color: #758d96;
}

.view_card_image {
    display: block;
    width: 95%;
    border: solid 1px gray;
    border-radius: 10px;
    margin-top: 10px;
}

.hide_card_image {
    display: none;
}

.closeViewButton {
    height: 40px;
    width: 10%;
    border-radius: 10px;
    background-color: green;
    border: solid 1px green;
    color: white;
    margin-left: 45%;
    margin-top: 10px;
}

.id_card_div {
    display: inline-flex;
    margin-left: 15%;
}

.driver_license_div {
    display: inline-flex;
    margin-top: 20px;
    margin-left: 15%;
}

.front_card {
    width: 45%;
}

.back_card {
    width: 45%;
}

.front_license {
    width: 45%;
}

.back_license {
    width: 45%;
}
.front_card_img {
    width: 300px;
    height: 300px;
    object-fit: cover;
}
.back_card_img {
    width: 300px;
    height: 300px;
    object-fit: cover;
}
.front_license_img {
    width: 300px;
    height: 300px;
    object-fit: cover;
}
.back_license_img {
    width: 300px;
    height: 300px;
    object-fit: cover;
}
.name_tag{
    margin-top: 20px;
    margin-left: 20px;
}