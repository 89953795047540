.elevate_panel{
    height: 60px;
    width: 100%;
    background-color: #15171c;
    vertical-align: middle;
    display: flex;
    box-align: center;
    text-align: center;
}
.elevate_logo {
	transform: scale(0.3);
	position: relative;
	left: 50%;
	margin-left: -500px;
    margin-top: -10px; 
}
.login_theme {
    background-color: whitesmoke;
    padding-left: 25px;
    width: 400px;
    position: relative;
    left: 50%;
    top: 250px;
    margin-left: -200px;
    border-radius: 30px;
    text-align: center;
}

.login_table {
    width: 350px;
    border-radius: 30px;
    border-spacing: 1px 10px;
    padding-right: 105px;
}

.login_table input {
    border-radius: 8px;
    width: 350px;
    height: 35px;
}

.login_table button {
    background-color: black;
    color: white;
    border-radius: 10px;
    width: 350px;
    height: 35px;
}

.title_login {
    text-align: center;
}

.style_text {
    text-align: left;
    font-size: 15px;
}

.link {
    color: rgb(64, 106, 221);
    background-color: transparent;
    text-decoration: none;
}

.style_forgot {
    text-align: right;
}
