.promos_contain{
    margin-left: 250px;
}
.promos_menu_bar_table{
    width: 95%;
    border-collapse: collapse;
    margin-top: 10px;
}
.promos_table{
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}
.promos_table_tr{
    border-bottom: solid 1px;
    border-color: gray;
}
.promos_table_th{
    color: black;
    background-color: gray;
}
.promos_table_tr:hover{
    background-color: whitesmoke;
}
.promos_title{
    text-align: left;
    font-size: 35px;
}
.promos_fleets_select{
    background-color: gray;
    margin-left: 15px;
    height: 40px;
    width: 10%;
    border-radius: 5px;
    color: whitesmoke;
    font-size: 100%;
    border: none;
}
.promos_select{
    background-color: gray;
    margin-left: 15px;
    height: 40px;
    width: 15%;
    border-radius: 5px;
    color: whitesmoke;
    font-size: 100%;
    border: none;
}
.promos_leftside_menu{
    text-align: left;
    width: 50%;
}
.promos_search{
    width: 30%;
    height: 40px;
    border-radius: 5px;
    font-size: 100%;
}
.btn_add_promos{
    width: 15%;
    height: 40px;
    border-radius: 5px;
    font-size: 100%;
    background-color: gray;
    color: whitesmoke;
    margin-left: 15px;
    border: none;
}
.promos_rightside_menu{
    text-align: right;
    width: 50%;
}
.payments_btn_previous{
    width: 15%;
    height: 40px;
    border-radius: 5px;
    background-color: gray;
    color: whitesmoke;
}
.page_btn_div{
    text-align: right;
}
.promos_batches_title{
    text-align: left;
    font-size: 35px;
}
.promos_batches_table{
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}
.promos_batches_table_th{
    color: black;
    background-color: gray;
}
.btn_promos_csv{
    height: 35px;
    width: 60px;
    background-color: #00c853;
    border-radius: 5px;
    color: whitesmoke;
}
.promo_batch_csv{
    background-color: #00c853;
    height: 35px;
    width: 60px;
    border-radius: 5px;
    color: whitesmoke;
    border: none;
    font-size: 0.75vw;
}