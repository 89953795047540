.banner_contain{
    margin-left: 250px;
}
.banner_table{
    width: 95%;
    border-collapse: collapse;
    margin-top: 10px;
}
.banner_table_tr{
    border-bottom: solid 1px;
    border-color: gray;
}
.banner_table_th{
    color: black;
    background-color: gray;
}
.banner_table_tr:hover{
    background-color: whitesmoke;
}
.banner_title{
    text-align: left;
    font-size: 35px;
}
.banner_leftside_menu{
    text-align: left;
    width: 50%;
}
.banner_search{
    width: 30%;
    height: 40px;
    border-radius: 5px;
    font-size: 1vw;
}
.btn_csv_banner{
    width: 15%;
    height: 40px;
    border-radius: 5px;
    font-size: 100%;
    background-color: gray;
    color: whitesmoke;
}
.banner_filter{
    width: 25%;
    height: 40px;
    border-radius: 5px;
    font-size: 1vw;
    background-color: gray;
    color: whitesmoke;
    margin-left: 15px;
}
.btn_add_banner{
    width: 25%;
    height: 40px;
    border-radius: 5px;
    font-size: 1vw;
    background-color: gray;
    color: whitesmoke;
    margin-left: 15px;
}
.banner_rightside_menu{
    text-align: right;
    width: 50%;
}
.banner_btn_previous{
    width: 15%;
    height: 40px;
    border-radius: 5px;
    background-color: gray;
    color: whitesmoke;
    font-size: 1vw;
}
.banner_btn_next{
    width: 15%;
    height: 40px;
    border-radius: 5px;
    background-color: gray;
    color: whitesmoke;
    font-size: 1vw;
}
.banner_pagination{
    width: 95%;
    margin-top: 25px;
}
.total_showing{
    text-align: left;
    color: gray;
}
.page_btn_div{
    text-align: right;
}