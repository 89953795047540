.reserved_vehicles_contain {
    margin-left: 250px;
}
.reserved_vehicles_title{
    font-size: 35px;
    margin-top: 5px;
    margin-bottom: 10px;
}
.reversed_vehicles_menu_filter{
    width:95%;
    margin-bottom: 5px;
    margin-top: 10px;
}
.reversed_vehicles_menu_filter button{
    height: 40px;
    border-radius: 10px;
    font-size: 100%;
    color: whitesmoke;
}
.reversed_vehicles_menu_filter select{
    height: 40px;
    border-radius: 10px;
    font-size: 100%;
    background-color: gray;
    color: whitesmoke;
}
.reserved_fleets_select{
    background-color: gray;
    float: right;
    border: solid 0.5px;
    border-color: gray;
}
.reversed_leftside_menu{
    width:100%;
}

.reversed_rightside_menu{
    display: inline-block;
    width: 100%;
}
.reversed_search{
    height: 40px;
    width: 30%;
    border-radius: 10px;
    font-size: 100%;
    border: solid 0.5px;
    border-color: gray;
}
.reserved_vehicles_table{
    width: 95%;
    font-size: 1vw;
    text-align: center;
    border-collapse: collapse;
    color: gray;
}
.battery{
    background-color: #000;
    border: 0.5px solid rgba(44, 64, 90, 0.2);
    height: 21px;
    position: relative;
    width: 50px;
    line-height: 20px;
    color: white;
    margin-top: 2px;
    border-radius: 3px;
    overflow: hidden;
}
.battery span{
    position: absolute;
    font-size: 11px;
    margin: 0;
    left: 0;
    right: 0;
    text-align: center;
}
.battery-level-80{
    background-color: #00c853;
    width:80%; 
    height: 100%;
    padding-left:4px;
    font-size:9px;
}
.battery-level-50{
    background-color: #3f51b5;
    width:50%; 
    height: 100%;
    padding-left:4px;
    font-size:9px;
}
.battery-level-75{
    background-color: #00c853;
    width:75%; 
    height: 100%;
    padding-left:4px;
    font-size:9px;
}
.battery-level-77{
    background-color: #00c853;
    width:77%; 
    height: 100%;
    padding-left:4px;
    font-size:9px;
}
.battery-level-91{
    background-color: #00c853;
    width:91%; 
    height: 100%;
    padding-left:4px;
    font-size:9px;
}

