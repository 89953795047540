.history_contain {
    margin-left: 250px;
}
.history_title{
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 5px;
}
.history_menu_filter{
    width:95%;
    margin-bottom: 25px;
    margin-top: 10px;

}
.history_menu_filter button{
    height: 40px;
    border-radius: 5px;
    font-size: 1vw;
    color: whitesmoke;
}
.history_menu_filter select{
    height: 40px;
    border-radius: 5px;
    font-size: 1vw;
    color: whitesmoke;
    background-color: gray;
}
.history_button_div{
    float: right;
}
.history_btn_get_csv{
    background-color: gray;
    margin-left: 20px;
    border: solid 0.5px;
    border-color: gray;
}
.history_btn_filter{
    background-color: gray;
    margin-left: 20px;
    border: solid 0.5px;
    border-color: gray;
}
.history_fleets_select{
    margin-left: 20px;
    border: solid 0.5px;
    border-color: gray;
}
.history_leftside_menu{
    width:50%;
}
.history_rightside_menu{
    width: 100%;
    float: right;
}
.history_search{
    height: 40px;
    width: 40%;
    border-radius: 10px;
    font-size: 1vw;
    border: solid 0.5px;
    border-color: gray;
}
.payment_select{
    border: solid 1px;
    border-color: gray;
    background-color: whitesmoke;
}
.active_toggle_filter{
    display: inline-block;
}
.delete_toggle_filter{
    display: inline-block;
}
.btn_csv{
    height: 60px;
    width: 100%;
    border-radius: 10px;
    font-size: 1vw;
    background-color: green;
    color: gray;
    float: right;
    margin-left: 25px;
}
.history_table_div{
    width: 80%;
}
.history_table_div::-moz-scrollbar-thumb {
    background-color: red;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}
.history_table{  
    width: 120%;
    font-size: 1vw;
    text-align: center;
    border-collapse: collapse;
    color: gray;
}
.history_table td{
    border-bottom: solid 1px;
    border-color: silver;
    border-spacing: 0px 0px;   
}
.history_table_tr:hover{
    background-color: whitesmoke;
}
.history_table_th{
    background-color: black;
    color: whitesmoke;
    font-weight: 50%;
}
.history_table_tr{
    color: #758d96;
}
.show_history_map{
    display: block;
    width: 100%;
}
.hide_history_map{
    display: none;
    visibility: hidden;
}