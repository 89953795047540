.org_contain {
    margin-left: 250px;
}
.hide_org_contain{
    visibility: hidden;
}
.org_title{
    font-size: 35px;
    margin-top: 25px;
}
.org_menu_filter{
    width:95%;
    margin-bottom: 25px;
    margin-top: 25px;
}
.org_leftside_menu{
    width:50%;
}
.org_country_select{
    height: 40px;
    width: 30%;
    border-radius: 10px;
    font-size: 1vw;
    border: solid 1px;
    border-color: gray;
}
.org_status_select{
    height: 40px;
    width: 30%;
    border-radius: 10px;
    font-size: 1vw;
    margin-left: 50px;
    border: solid 1px;
    border-color: gray;
}
.org_rightside_menu{
    width: 50%;
    text-align: right;

}
.org_location_search{
    height: 40px;
    width: 50%;
    border-radius: 10px;
    font-size: 1vw;
    border: solid 1px;
    border-color: gray ;
}
.org_btn_add_location{
    height: 40px;
    width: 35%;
    border-radius: 10px;
    font-size: 1vw;
    background-color: gray;
    color: white;
    margin-left: 25px;
    border: solid 1px;
    border-color: gray;
}
.org_table{
    width: 95%;
    font-size: 1vw;
    /*text-align: center;*/
    border-collapse:collapse;
}
/*.org_table td{
    border-bottom: solid 1px;
    border-color: gray;
    border-spacing: 0px 0px;
}*/
.org_table button{
    width: 20%;
    height: 35px;
    margin-right:5px;
    margin-top: 5px;
    font-size: 1vw;
}
/*.org_table_th{
    background-color: black;
    color: whitesmoke;
    font-weight: 50%;
}*/
.org_action_button{
    border-radius: 5px;
    color: whitesmoke;
    background-color: grey;
}
.dataTables_paginate{
    float:right
}
.add_location_hide{
    display: none;
}
.add_location_show{
    display: block;
    position: absolute;
    top: 15%;
    margin-left: 250px;
    height: 550px;
    width: 80%;
    background-color: #f8f8f8;
    border-radius: 10px;
    margin-top: 50px;
}
.confirm_add_location_hide{
    visibility: hidden;
}
.confirm_add_location_show{
    position: absolute;
    top: 35%;
    left:50%;
    margin-left: -20%;
    height: 150px;
    width: 40%;
    background-color: #c2bfbf;
    border-radius: 5px;
    border: solid 1px;
    border-color: black;
    text-align: center;
    padding-top: 25px;
    z-index: 9999;
}
.add_location_confirm_title{
    margin-top: 50px;
    font-size: 1vw;
}
.add_location_confirm_button{
    width: 23%;
    height: 35px;
    border-radius: 5px;
    color: whitesmoke;
    background-color: #545096;
    border-color: #545096;
    box-shadow: none;
}
.add_location_form_header{
    margin-top: 25px;
    margin-left: 25px;
    font-size: 1.5vw;
}
.form {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
      
}
.form-group label {
    font-size: 20px;
}
.form-group  input {
    margin-top: 6px;
    min-width: 18em;
    height: 37px;
    padding: 0px 10px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    background-color: #f3f3f3;
    border: 0;
    border-radius: 4px;
    margin-bottom: 15px;
    transition: all 250ms ease-in-out;
}
.form-group  select {
    margin-top: 6px;
    min-width: 18em;
    height: 37px;
    padding: 0px 10px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    background-color: #f3f3f3;
    border: 0;
    border-radius: 4px;
    margin-bottom: 15px;
    transition: all 250ms ease-in-out;
}
.form-group  output {
    margin-top: 6px;
    min-width: 18em;
    height: 37px;
    padding: 0px 10px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    background-color: #f3f3f3;
    border: 0;
    border-radius: 4px;
    margin-bottom: 15px;
    transition: all 250ms ease-in-out;
    float:left;
}
.add_org_footer{
    align-items: center;
    margin-left: 40%;
}
.add_org_footer_hide{
    visibility: hidden;
}
.btn_add_location{
    height: 40px;
    border-radius: 10px;
    font-size: 1vw;
    background-color: #545096;
    color: white;
    margin-left: 25px;
    border: solid 1px;
    border-color: #545096;
    padding-left: 10px;
    padding-right: 10px;
}
.show_delete_location_form{
    display: block;
}
.hide_delete_location_form{
    display: none;
}
.delete_location_cancel_button{
    width: 15%;
    height: 35px;
    border-radius: 5px;
    color: whitesmoke;
    background-color: gray;
    border-color: gray;
    box-shadow: none;
}
.add_role_confirm_button{
    width: 15%;
    height: 35px;
    border-radius: 5px;
    color: whitesmoke;
    background-color: #545096;
    border-color: #545096;
    box-shadow: none;
}
.delete_location_confirm_title{
    margin-top: 50px;
    font-size: 1vw;
}
.hide_delete_location_form{
    display: none;
}
.show_delete_location_form{
    position: absolute;
    top: 30%;
    margin-left: 35%;
    height: 175px;
    width: 40%;
    background-color: #f8f8f8;
    border-radius: 10px;
    margin-top: 50px;
    border: solid 1px gray;
    text-align: center;
}
.btn_cancel_adding{
    height: 40px;
    border-radius: 10px;
    font-size: 1vw;
    background-color: gray;
    color: white;
    margin-left: 25px;
    border: solid 1px;
    border-color: gray;
    padding-left: 10px;
    padding-right: 10px;
} 
.view_location_hide{
    display: none;
}
.view_location_show{
    display: block;
    position: absolute;
    top: 15%;
    margin-left: 250px;
    height: 550px;
    width: 80%;
    background-color: #f8f8f8;
    border-radius: 10px;
    margin-top: 50px;
}
.view_location_footer{
    margin-left: 40%;
}
.edit_location_hide{
    display: none;
}
.edit_location_show{
    display: block;
    position: absolute;
    top: 15%;
    margin-left: 250px;
    height: 550px;
    width: 80%;
    background-color: #f8f8f8;
    border-radius: 10px;
    margin-top: 50px;
}
.edit_org_footer{
    align-items: center;
    margin-left: 40%;
}
.edit_org_footer_hide{
    visibility: hidden;
}
.confirm_edit_location_hide{
    display: none;
}
.confirm_edit_location_show{
    display: block;
    position: absolute;
    top: 35%;
    left:50%;
    margin-left: -20%;
    height: 150px;
    width: 40%;
    background-color: #c2bfbf;
    border-radius: 5px;
    border: solid 1px;
    border-color: black;
    text-align: center;
    padding-top: 25px;
    z-index: 9999;
}

  