.div_all {
    margin-left: 250px;
}
.payments_analysis_info{
    border-radius: 5px;
    width: 30%;
    height: 10px;
    color: gray;
    font-size: 13px;
}
.payments_dashboard_table {
    width: 95%;
    border-spacing: 20px 10px;
    margin-left: -20px;
}
.payments_title {
    position: relative;
    font-size: 35px;
}
.payments_filter_bar{
    width:95%;
    position: relative;
}
.payments_filter_div{
    text-align: right;
    margin-bottom: 10px;
}
.payments_fleets_select {
    border-radius: 9px;
    width: 10%;
    height: 40px;
    margin-right: 25px;
    font-size: 100%;
}
.payments_currencies_select{
    border-radius: 9px;
    width: 10%;
    height: 40px;
    margin-right: 25px;
    font-size: 100%;
}
.payments_analysis{
    text-align: left;
}
.payments_type_icon{
    width: 30%;
    text-align: center;
}
.payments_analysis_content{
    width: 70%;
}
.td_analysis {
    width: 50%;
    text-align: center;
}
.payments_table_div{
    width: 100%;
    height: auto;
    border: solid 1px;
    border-color: black;
    border-radius: 5px;
    margin-bottom: 10px;
}
.payments_table{
    width: 95%; 
    height: 100%;
    font-size: 1vw; 
    margin: auto;
}
.payments_table p{
    margin: 0px;
}
.payments_table b{
    color: black;
}
.payments_icon{
    font-size: 200%;
}
.payments_chart{
    width: 95%;
}
.payments_tab_panel{
    text-align: center;
}
