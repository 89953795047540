.walletcredit_table{
    width: 95%;
}
.walletcredit_table_tr{
    border-bottom: solid 1px;
    border-bottom-color: gray;
}
.walletcredit_table_th{
    color: black;
}
.wallet_creadit_title{
    text-align: left;
    font-size: 1vw;
}
.wallet_creadit_payments_btn_csv{
    height: 40px;
    border-radius: 5px;
    background-color: green;
    color: whitesmoke;
    font-size: 1vw;
}
.wallet_creadit_payments_filter{
    height: 40px;
    border-radius: 5px;
    background-color: gray;
    color: whitesmoke;
    margin-left: 10px;
    font-size: 1vw;
}
.walletcredit_leftside_menu{
    text-align: left;
    width: 50%;
}
.walletcredit_search{
    width: 30%;
    height: 40px;
    border-radius: 5px;
}
.walletcredit_rightside_menu{
    text-align: right;
    width: 50%;
}
.payments_btn_previous{
    height: 40px;
    border-radius: 5px;
    background-color: gray;
    color: whitesmoke;
}
.payments_btn_next{
    width: 15%;
    height: 40px;
    border-radius: 5px;
    background-color: gray;
    color: whitesmoke;
    margin-left: 15px;
}
.wallet_debit_pagination{
    width: 95%;
    margin-top: 25px;
}
.total_showing{
    text-align: left;
    color: gray;
}
.page_btn_div{
    text-align: right;
}