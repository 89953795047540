.tickets_contain{
    margin-left: 250px;
}
.tickets_table{
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}
.tickets_tool_table{
    width: 95%;
    border-collapse: collapse;
    margin-top: 10px;
}
.tickets_tool_table_tr{
    border-bottom: solid 1px;
    border-color: gray;
}
.tickets_tool_table_th{
    color: black;
    background-color: gray;
}
.tickets_table_tr:hover{
    background-color: whitesmoke;
}
.tickets_title{
    text-align: left;
    font-size: 35px;
}
.tickets_leftside_menu{
    text-align: left;
    width: 50%;
}
.tickets_search{
    width: 30%;
    height: 40px;
    border-radius: 5px;
    font-size: 1vw;
    border: solid 1px;
    color:gray
}
.btn_csv_tickets{
    width: 10%;
    height: 40px;
    border-radius: 5px;
    font-size: 1vw;
    background-color: gray;
    color: whitesmoke;
    border: none;
}
.tickets_filter{
    width: 10%;
    height: 40px;
    border-radius: 5px;
    font-size: 1vw;
    background-color: gray;
    color: whitesmoke;
    margin-left: 15px;
    border: none;
}
.btn_report_tickets{
    width: 15%;
    height: 40px;
    border-radius: 5px;
    font-size: 1vw;
    background-color: gray;
    color: whitesmoke;
    margin-left: 15px;
    border: none;
}
.btn_add_tickets{
    height: 40px;
    border-radius: 5px;
    font-size: 1vw;
    background-color: gray;
    color: whitesmoke;
    margin-left: 15px;
    border: none;
}
.ticket_report_button{
    height: 35px;
    padding: 5px;
    border: none;
    border-radius: 5px;
    background-color: #2acaea;
    color: whitesmoke;
}
.tickets_fleets_select{
    width: 12%;
    height: 40px;
    border-radius: 5px;
    font-size: 1vw;
    background-color: gray;
    color: whitesmoke;
    margin-left: 15px;
    border: none;
}
.tickets_rightside_menu{
    text-align: right;
    width: 50%;
}
.ticket_status_filter{
    display: inline-flex;
    text-align: center;
    margin-top: 25px;
    margin-left: 40%;
}
.all_ticket_filter{
    background-color: #3c8dbc;
    border: none;
    height: 35px;
    width: 100px;
    color:whitesmoke;
}
.all_ticket_select{
    width: 0;
	height: 0;
	border-top: 17.5px solid #d58412;
	border-left: 15px solid #3c8dbc;
	border-bottom: 18.5px solid #d58412;
}
.all_ticket_unselect{
    height: 35px;
    width: 10px;
    background-color: #d58412;
}
.pending_ticket_filter{
    background-color: #d58412;
    border: none;
    height: 35px;
    width: 100px;
    color:whitesmoke;
}
.pending_ticket_select{
    width: 0;
	height: 0;
	border-top: 17.5px solid #d9534f;
	border-left: 15px solid #d58412;
	border-bottom: 18.5px solid #d9534f;
}
.pending_ticket_unselect{
    height: 35px;
    width: 10px;
    background-color: #d9534f;
}
.in_progress_ticket_filter{
    background-color: #d9534f;
    border: none;
    height: 35px;
    width: 100px;
    color:whitesmoke;
}
.in_progress_ticket_select{
    width: 0;
	height: 0;
	border-top: 17.5px solid #398439;
	border-left: 15px solid #d9534f;
	border-bottom: 18.5px solid #398439;
}
.in_progress_ticket_unselect{
    height: 35px;
    width: 10px;
    background-color: #398439;
}
.done_ticket_select{
    width: 0;
	height: 0;
	border-top: 17.5px solid #d9534f;
	border-right: 15px solid #398439;
	border-bottom: 18.5px solid #d9534f;
}
.done_ticket_filter{
    background-color: #398439;
    border: none;
    height: 35px;
    width: 100px;
    color:whitesmoke;
}
.payments_btn_previous{
    width: 15%;
    height: 40px;
    border-radius: 5px;
    background-color: gray;
    color: whitesmoke;
}
.payments_btn_next{
    width: 15%;
    height: 40px;
    border-radius: 5px;
    background-color: gray;
    color: whitesmoke;
}
.tickets_pagination{
    width: 95%;
    margin-top: 25px;
}
.total_showing{
    text-align: left;
    color: gray;
}
.page_btn_div{
    text-align: right;
}
.issue_get_ticket_button{
    height: 35px;
    padding: 5px;
    border: none;
    border-radius: 5px;
    background-color: rgb(255, 101, 80);
    color: whitesmoke;
}
.show_ticket_table{
    display: block;
}
.hide_ticket_table{
    display: none;
}
.show_add_ticket_form{
    display: flex;
}
.hide_add_ticket_form{
    display: none;
}