.notifications_contain{
    margin-left: 250px;
}
.notifications_table{
    width: 95%;
    border-collapse: collapse;
    margin-top: 10px;
}
.notifications_table_tr{
    border-bottom: solid 1px;
    border-color: gray;
}
.notifications_table_th{
    color: black;
    background-color: gray;
}
.notifications_table_tr:hover{
    background-color: whitesmoke;
}
.notifications_title{
    text-align: left;
    font-size: 35px;
}
.notifications_leftside_menu{
    text-align: left;
    width: 50%;
}
.notifications_search{
    width: 30%;
    height: 40px;
    border-radius: 5px;
    font-size: 1vw;
}
.btn_csv_notifications{
    width: 15%;
    height: 40px;
    border-radius: 5px;
    font-size: 1vw;
    background-color: gray;
    color: whitesmoke;
}
.notifications_filter{
    width: 20%;
    height: 40px;
    border-radius: 5px;
    font-size: 1vw;
    background-color: gray;
    color: whitesmoke;
    margin-left: 15px;
}
.btn_create_campaigns{
    width: 20%;
    height: 40px;
    border-radius: 5px;
    font-size: 0.75vw;
    background-color: gray;
    color: whitesmoke;
    margin-left: 15px;
}
.btn_add_card{
    width: 30%;
    height: 40px;
    border-radius: 5px;
    font-size: 0.75vw;
    background-color: gray;
    color: whitesmoke;
    margin-left: 15px;
}
.notifications_rightside_menu{
    text-align: right;
    width: 50%;
}
.payments_btn_previous{
    width: 15%;
    height: 40px;
    border-radius: 5px;
    background-color: gray;
    color: whitesmoke;
}
.payments_btn_next{
    width: 15%;
    height: 40px;
    border-radius: 5px;
    background-color: gray;
    color: whitesmoke;
}
.notifications_pagination{
    width: 95%;
    margin-top: 25px;
}
.total_showing{
    text-align: left;
    color: gray;
    font-size: 1vw;
}
.page_btn_div{
    text-align: right;
    font-size: 1vw;
}