.feedback_options_contain{
    margin-left: 250px;
}
.feedback_options_table{
    width: 95%;
    border-collapse: collapse;
    margin-top: 10px;
}
.feedback_options_table_tr{
    border-bottom: solid 1px;
    border-color: gray;
}
.feedback_options_table_th{
    color: black;
    background-color: gray;
}
.feedback_options_table_tr:hover{
    background-color: whitesmoke;
}
.feedback_options_title{
    text-align: left;
    font-size: 35px;
}
.feedback_options_leftside_menu{
    text-align: left;
    width: 50%;
}
.feedback_options_search{
    width: 30%;
    height: 40px;
    border-radius: 5px;
    font-size: 1vw;
}
.btn_csv_feedback_options{
    width: 15%;
    height: 40px;
    border-radius: 5px;
    font-size: 1vw;
    background-color: gray;
    color: whitesmoke;
}
.feedback_options_filter{
    width: 15%;
    height: 40px;
    border-radius: 5px;
    font-size: 1vw;
    background-color: gray;
    color: whitesmoke;
    margin-left: 15px;
}
.btn_report_feedback_options{
    width: 20%;
    height: 40px;
    border-radius: 5px;
    font-size: 1vw;
    background-color: gray;
    color: whitesmoke;
    margin-left: 15px;
}
.btn_add_feedback_options{
    width: 20%;
    height: 40px;
    border-radius: 5px;
    font-size: 1vw;
    background-color: gray;
    color: whitesmoke;
    margin-left: 15px;
}
.feedback_options_fleets_select{
    width: 15%;
    height: 40px;
    border-radius: 5px;
    font-size: 1vw;
    background-color: gray;
    color: whitesmoke;
    margin-left: 15px;
}
.feedback_options_rightside_menu{
    text-align: right;
    width: 50%;
}
.payments_btn_previous{
    width: 15%;
    height: 40px;
    border-radius: 5px;
    background-color: gray;
    color: whitesmoke;
}
.payments_btn_next{
    width: 15%;
    height: 40px;
    border-radius: 5px;
    background-color: gray;
    color: whitesmoke;
}
.feedback_options_pagination{
    width: 95%;
    margin-top: 25px;
}
.total_showing{
    text-align: left;
    color: gray;
}
.page_btn_div{
    text-align: right;
}
.btn_feedback_options_color{
    height: 40px;
    width: 60px;
    background-color: gray;
    border-radius: 7.5px;
}
.messages_contain{
    display: flex;
    width: 95%;
}
.contact_list{
    width: 30%;
    height: 600px;
    border: solid 1px black;
    border-radius: 10px;
}
.message_region{
    width: 70%;
    height: 600px;
    margin-left: 10px;
}
.menu_toolbar{
    display: block;
}
.menu_toolbar_title{
    text-align: center;
}
.search_chat_user{
    height: 35px;
    border: solid 1px black;
    border-radius: 7.5px;
    margin-left: 5px;
    width: 80%;
}
.chat_box{
    height: 540px;
    border: solid 1px black;
    border-radius: 10px;
    overflow-y: auto;
    padding-top: 10px;
    margin-bottom: 10px;
}
.message{
    margin-top: 10px;
    display: flex;    
    height: 50px;
}
.message_box{
    width: 95%;
    border: solid 1px black;
    border-radius: 10px 0 0 10px;
    font-size: 1vw;
}
.send_button{
    width: 5%;
    border: solid 1px #00A5E3;
    border-radius: 0 10px 10px 0;
    background-color: #00A5E3;
    font-size: 1vw;
    color: blanchedalmond;
}
.orther_one_mes{
    display: flex;
    max-width: 80%;
    margin-bottom: 5px;
    padding: 10px;
    margin-left: 5px;
    clear:both;
    float: left;
}
.orther_one_mes_content{
    border: solid 1px #C0C0C0;
    border-radius: 5px;
    background-color: #C0C0C0;
    color: black;
    height: 50px;
    padding: 5px;
    min-width: 25%;
}
.my_mes{
    display: flex;
    max-width: 80%;
    margin-bottom: 5px;
    padding: 10px;
    margin-right: 5px;
    clear: both;
    float: right;
}
.my_mes_content{
    display: block;
    border: solid 1px  #F4A460;
    border-radius: 5px;
    background-color: #F4A460; 
    color: white;
    height: 50px;
    padding: 5px;
}
.user_contact{
    display: grid;
    /*margin-left: 5px;*/
    margin-top: 5px;
    height: 75px;
    width: 100%;
    border: none;
    background-color: #C0C0C0;
    font-size: 1vw;
    /*border-radius: 25px 0 0 25px;*/
    color: black;
    text-align: left;
}
.chat_user_avatar{
    margin-top: 3px;
}
.user_contact_name{
    margin-top: -65px;
    margin-left: 85px;
}
.unread_mes{
    font-size: 0.75vw;
    height: 1.25vw;
    width: 1.25vw;
    color: white;
    background-color: red;
    display: inline-block;
    text-align: center;
    border-radius: 0.625vw;
    vertical-align: middle;
    padding-bottom: -0.25vw;
}