.map_contain{
    margin-top: 15px;
    margin-left: 250px;
}
.title_map {
    font-size: 35px;
}
.table_map {
    text-align:left;
    width: 100%;
    border-spacing: 1px;
    margin-top: 10px;
}
.menubar_table{
    width: 95%;
}
.search_bicycle_filter{
    margin-left:-10%;
}
.map_location_select {
    border-radius: 8px;
    height: 30px;
}
.vehicle_search {
    border-radius: 8px 0px 0px 8px;
    height: 30px;
    width: 230px;
}
.search_vehicle_enter{
    border-radius: 0px 8px 8px 0px;
    height: 30px;
    background-color: #444 ;
    border: solid 1px #444;
    color: aliceblue;
}
.select_vehicle {
    border-radius: 8px;
    height: 30px;
    margin-left: 10px;
}
.map_btn_filter {
    background-color: gray;
    color: white;
    border: none;
    border-radius: 8px;
    height: 30px;
    margin-left: 10px;
}
.show_all_map{
    display:block;
}
.hide_all_map{
    display:none;
}
.show_search_map{
    display: block;
}
.hide_search_map{
    display: none;
}
.icon_marker{
    height: 30%;
    width: 30%;
}
.icon1 {
    color: rgb(104, 20, 20);
}
.icon2  {
    color: orangered;
}
.icon3 {
    color: rgb(10, 199, 10);
}
.icon4 {
    color: rgb(5, 47, 16);
}
.icon5 {
    color: blue;
}
.icon6 {
    color:purple;
}
.icon7 {
    color: red;
}
.menubar_left {
    float:left;
}
.menubar_right {
    width: 90%;
    display: inline-flex;
    float: right;
}
.leaflet-container {
  width: 95%;
}

.td-map{
    vertical-align: top;
    padding-top: 20px;
}

.td-vehicle-info{
    vertical-align: top;
 
}
.show_no_vehicle_found{
    position: absolute;
    top: 40%;
    left: 40%;
    display: block;
    height: 80px;
    width: 15%;
    border: solid 1px #CCCCCC;
    border-radius: 10px;
    background-color: #444;
    text-align: center;
    z-index: 999;
    color: white;
}
.hide_no_vehicle_found{
    display: none;
}
.no_vehicle_found_button{
    height: 37px;
    width: 30%;
    border: solid 1px #669999;
    border-radius: 10px;
    background-color: #669999;
    color: white;
    margin-bottom: 10px;
}