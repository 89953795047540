@import url("https://cdn.syncfusion.com/ej2/material.css");
.div_all {
    margin-left: 250px;
    margin-top: 15px;
}
.vehicle_analysis_info{
    width: 33%;
    border: none;
    color: gray;
    font-size: 13px;
}
.vehicle_analysis_info_div{
    height: 175px;
    border: solid 1px;
    border-color: black;
    border-radius: 15px;
    margin-top: 20px;
}
.rides_analysis_info{
    width: 33%;
    color: gray;
    font-size: 13px;
}
.rides_analysis_info_div{
    height: 175px;
    border: solid 1px;
    border-color: black;
    border-radius: 15px;
    margin-top: 20px;
}
.user_analysis_info{
    width: 33%;
    height: 10px;
    color: gray;
    font-size: 13px;
}
.user_analysis_info_div{
    height: 175px;
    border: solid 1px;
    border-color: black;
    border-radius: 15px;
    margin-top: 20px;
}
.dashboard_home_table {
    border-spacing: 20px 10px;
    border: none;
    width: 95%;
}
.total_revenue {
    background-color: black;
    color: white;
    border-radius: 10px;
    height: 40px;
}
.table_revenue {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 2 vw;
    margin: auto;
    
}
.td_revenue_title{
    width: 50%;
    text-align: center;
}
.td_revenue_value{
    width: 50%;
    text-align: center;
}
.revenue_title{
    font-size: 1.5vw;
    vertical-align: middle;
    margin-block: inherit;
}
.dashboard_title {
    position: relative;
    font-size: 35px;
}
.revenue_value{
    font-size: 1.5vw;
    vertical-align: middle;
    margin-block: inherit;
}
.filter_bar{
    width:100%;
    position: relative;
}
.area_select {
    border-radius: 9px;
    width: 40%;
    height: 40px;
    margin-right: 25px;
    font-size: 1vw;
    border: solid 1px;
    border-color: gray;
}
.date_select {
    border-radius: 9px;
    width: 40%;
    height: 40px;
    font-size: 1vw;
    border: solid 1px;
    border-color: gray;
}
.td_analysis {
    width: 50%;
    text-align: center;
}
.td_analysis_contain {
    font-size: 1vw;
}
.vehicle_table{
    width: 100%; 
    height: 100%;
}
.vehicle_icon{
    position:absolute;
    font-size: 300%;
    /*margin-top: -10px;*/
    padding: 1%;
}
.rides_analysis_table{
    width: 100%; 
    height: 100%;
}
.rides_icon{
    position:absolute;
    font-size: 300%;
    padding: 1%;
}
.rider_icon{
    position:absolute;
    font-size: 300%;
    padding: 1%;
}
.user_table{
    width: 100%; 
    height: 100%;
    font-size: 100%; 
}

.revenue_chart {
    height: 100%;
}
.rides_chart {
    height: 100%;
}
.user_chart {
    height: 100%;
}