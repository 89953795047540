.running_contain {
    margin-left: 250px;
}
.running_title{
    font-size: 35px;
    margin-top: 10px;
}
.running_menu_filter{
    width:95%;
    margin-bottom: 25px;
    margin-top: 10px;

}
.running_menu_filter button{
    height: 40px;
    border-radius: 10px;
    font-size: 1vw;
    color: whitesmoke;
}
.running_menu_filter select{
    background-color: gray;
    height: 40px;
    border-radius: 10px;
    font-size: 1vw;
    color: whitesmoke;
    float: right;
}

.btn_filter{
    background-color: gray;
    margin-left: 20px;
    padding-left: 25px;
    float: right;
}
.running_leftside_menu{
    width:50%;
}
.running_rightside_menu{
    width: 100%;
}
.running_search{
    height: 40px;
    width: 60%;
    border-radius: 10px;
    font-size: 1vw;
}
.running_table{
    width: 95%;
    font-size: 1vw;
    text-align: center;
    border-collapse: collapse;
}
.running_table td{
    border-bottom: solid 1px;
    border-color: silver;
    border-spacing: 0px 0px;   
}
.running_table_tr:hover{
    background-color: whitesmoke;
}
.running_table_th{
    background-color: black;
    color: whitesmoke;
    font-weight: 50%;
}
.running_table_tr{
    color: #758d96;
}
.force_stop_action_button{
    width: 110px;
    height: 37px;
    border-radius: 5px;
}
.open_request_div{
    width: 110px;
}
.request_table{
    display: inline-flex;
}
.unlock_request_table{
    width: 50%;
    font-size: 0.5vw;
    text-align: center;
    border-collapse: collapse;
}
.unlock_request_table td{
    border-bottom: solid 1px;
    border-color: silver;
    border-spacing: 0px 0px;   
}
.unlock_request_table_tr:hover{
    background-color: whitesmoke;
}
.unlock_request_table_th{
    background-color: black;
    color: whitesmoke;
    font-weight: 30%;
}
.unlock_request_table_tr{
    color: #758d96;
}
.verify_unlock_action_button{
    height: 37px;
    border-radius: 5px;
    background-color: cyan;
    border-color: cyan;
}
.verify_lock_action_button{
    height: 37px;
    border-radius: 5px;
    background-color: red;
    border-color: red;
    color: white;
}
.sr-only:has(.page-item){
    background-color: rgba(53, 163, 159, 0.8);
}
.page-item:active{
    background-color: rgba(53, 163, 159, 0.8);
}
.active{
    background-color: rgba(53, 163, 159, 0.8);
}
