.payment_gateway_contain{
    margin-left: 250px;
}
.payment_gateway_table{
    width: 95%;
    border-collapse: collapse;
    margin-top: 10px;
}
.payment_gateway_table_tr{
    border-bottom: solid 1px;
    border-color: gray;
}
.payment_gateway_table_th{
    color: black;
    background-color: gray;
}
.payment_gateway_table_tr:hover{
    background-color: whitesmoke;
}
.payment_gateway_title{
    text-align: left;
    font-size: 35px;
}
.payment_gateway_leftside_menu{
    text-align: left;
    width: 40%;
}
.payment_gateway_search{
    width: 30%;
    height: 40px;
    border-radius: 5px;
    font-size: 100%;
}
.payment_gateway_rightside_menu{
    text-align: right;
    width: 60%;
}
.payments_btn_previous{
    width: 15%;
    height: 40px;
    border-radius: 5px;
    background-color: gray;
    color: whitesmoke;
}
.payments_btn_next{
    width: 15%;
    height: 40px;
    border-radius: 5px;
    background-color: gray;
    color: whitesmoke;
}
.payment_gateway_pagination{
    width: 95%;
    margin-top: 25px;
}
.total_showing{
    text-align: left;
    color: gray;
}
.page_btn_div{
    text-align: right;
}
.payment_gateway_all_filter_button_el{
    height: 45px;
    width:15%;
    border: solid;
    border-color: gray;
    border-radius: 5px;
    border-width:thin;
    background-color:whitesmoke;
    margin-right:5%;
    cursor: pointer;
    font-size: 1.25em;
    font-family: "Times New Roman";
    text-align: left;
}
.payment_gateway_succeeded_filter_button_el{
    height: 45px;
    width:15%;
    border: solid;
    border-color: gray;
    border-radius: 5px;
    border-width:thin;
    background-color:whitesmoke;
    margin-right:5%;
    cursor: pointer;
    font-size: 1.25em;
    font-family: "Times New Roman";
    text-align: left;
}
.payment_gateway_refund_filter_button_el{
    height: 45px;
    width:15%;
    border: solid;
    border-color: gray;
    border-radius: 5px;
    border-width:thin;
    background-color:whitesmoke;
    margin-right:5%;
    cursor: pointer;
    font-size: 1.25em;
    font-family: "Times New Roman";
    text-align: left;
}
.payment_gateway_uncaptured_filter_button_el{
    height: 45px;
    width:15%;
    border: solid;
    border-color: gray;
    border-radius: 5px;
    border-width:thin;
    background-color:whitesmoke;
    margin-right:5%;
    cursor: pointer;
    font-size: 1.25em;
    font-family: "Times New Roman";
    text-align: left;
}
.payment_gateway_failed_filter_button_el{
    height: 45px;
    width:15%;
    border: solid;
    border-color: gray;
    border-radius: 5px;
    border-width:thin;
    background-color:whitesmoke;
    margin-right:5%;
    cursor: pointer;
    font-size: 1.25em;
    font-family: "Times New Roman";
    text-align: left;
}

.payment_gateway_all_filter_button_el_click{
    height: 45px;
    width:15%;
    border: solid;
    border-color: gray;
    border-radius: 5px;
    border-width:thin;
    background-color:rgba(106, 90, 205,0.7);
    margin-right:5%;
    cursor: pointer;
    font-size: 1.25em;
    font-family: "Times New Roman";
    text-align: left;
}
.payment_gateway_succeeded_filter_button_el_click{
    height: 45px;
    width:15%;
    border: solid;
    border-color: gray;
    border-radius: 5px;
    border-width:thin;
    background-color:rgba(106, 90, 205,0.7);
    margin-right:5%;
    cursor: pointer;
    font-size: 1.25em;
    font-family: "Times New Roman";
    text-align: left;
}
.payment_gateway_refund_filter_button_el_click{
    height: 45px;
    width:15%;
    border: solid;
    border-color: gray;
    border-radius: 5px;
    border-width:thin;
    background-color:rgba(106, 90, 205,0.7);
    margin-right:5%;
    cursor: pointer;
    font-size: 1.25em;
    font-family: "Times New Roman";
    text-align: left;
}
.payment_gateway_uncaptured_filter_button_el_click{
    height: 45px;
    width:15%;
    border: solid;
    border-color: gray;
    border-radius: 5px;
    border-width:thin;
    background-color:rgba(106, 90, 205,0.7);
    margin-right:5%;
    cursor: pointer;
    font-size: 1.25em;
    font-family: "Times New Roman";
    text-align: left;
}
.payment_gateway_failed_filter_button_el_click{
    height: 45px;
    width:15%;
    border: solid;
    border-color: gray;
    border-radius: 5px;
    border-width:thin;
    background-color:rgba(106, 90, 205,0.7);
    margin-right:5%;
    cursor: pointer;
    font-size: 1.25em;
    font-family: "Times New Roman";
    text-align: left;
}
